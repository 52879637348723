.home-container {
  position: relative;
  /* min-height: 100vh; */
  text-align: center;
  width: 100%;
}

.home-logo {
  position: relative;
  height: 300px;
  background-color: #fffeff;
}

.logo-container {
  background-color: #fffeff;
}

.home-details {
  font-size: 16px;
  padding: 50px 25px;
  font-family: "Roboto Condensed", sans-serif;
  background-color: #ebebeb;
}

.home-header {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: bold;
}

.reviews-title {
  font-family: "Raleway", sans-serif;
  font-weight: bold;
  margin-top: 5vh;
}

.services-container {
  padding: 25px;
}

.home-text {
  position: relative;
  color: rgb(63, 63, 63);
  top: 40vh;
  text-align: center;
  font-family: "Khula", sans-serif;
  font-size: 1.9rem;
}

/* START BRADY BOOTSTRAP STUFF */

.about-description {
  /*width: 50%;*/
  padding-right: 5%;
  padding-left: 5%;
  margin-bottom: 4vh;
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
  font-size: medium;
}

.about-job-header {
  color: rgb(63, 63, 63);
  font-family: "Raleway", sans-serif;
}

.about-job-title {
  color: black;
}

.about-photo {
  display: inline-block;
  width: 150px;
  height: 150px;

  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.category-img-container {
  text-align: center;
  /* height: 20vh; */
  overflow: hidden;
  text-align: center;
  align-items: center;
}

.category-img {
  display: inline-block;
  height: 10vh;
  margin-bottom: 2vh;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

@media only screen and (min-width: 600px) {
  .about-description {
    font-size: 18px;
  }

  /* .home-header-bg {
    height: 60vh;
  } */
  .home-image {
    width: 50%;
  }

  .home-details {
    padding: 100px 200px;
    font-size: 20px;
  }

  .category-img {
    display: inline-block;
    height: 12vh;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
  }
}

@media only screen and (min-width: 1200px) {
  .home-details {
    padding: 80px 300px;
    font-size: 24px;
  }
}
