.footer-container {
  width: 100% !important;
  /* position: absolute;
  bottom: 0; */
  /* margin: 0 !important; */
  height: 170px;
  background-color: #d32f2f;

  text-align: center;

  /* border-top-left-radius: 25pt;
  border-top-right-radius: 25pt; */
}

.footer-wrapper {
  padding-top: 20px;
}

.footer-text {
  color: white;

  font-weight: 400;
  font-size: small;
  text-align: center;
}

.footer-links {
  text-align: right;
}
.footer-link {
  text-decoration: none;
  color: darkgray;
  font-size: small;
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.cloudsurf-text {
  font-size: large;
  font-weight: bold;
  color: #42a5f5;
  text-decoration: none;
}

.cloudsurf-text:hover {
  /* text-decoration: none; */
  color: #2196f3;
}

.footer-links-title {
  margin: 0;
}

.footer-company-name {
  display: inline;
}

.footer-company-icon {
  display: inline;
  width: 80px;
  margin-right: 5px;
  /* margin-bottom: 10px; */
}

.footer-icons {
  text-align: center;
  padding-top: 2vh;
  font-size: x-large;
}

.footer-icon {
  padding-left: 10px;
  padding-right: 10px;
  color: white;

  padding-bottom: 10px;
}

.footer-icon:hover {
  /* color: rgb(0, 153, 255); */
  color: #39a0ed;
}
