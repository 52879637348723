.services-contact-us {
  background-color: #eeeeee;
  padding: 40px;
  border-radius: 25pt;
}

/* --------- kbd Button ---------- */
.services-contact-btn {
  position: relative;
  top: 25%;
  text-align: center;
  text-decoration: none !important;

  width: 75%;

  height: 50px;

  display: flex;
  margin: auto;

  /* margin-top: 5% !important; */

  font-family: "Montserrat", sans-serif;
  font-size: 0.9rem;
  font-weight: 800;
  padding: 15px;
  color: white;
  background-color: #0091ea;
  justify-content: center;

  text-decoration: none;
  border: none;
  border-radius: 100px;
  box-shadow: 0px 8px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.5s ease 0s;
  cursor: pointer;
  outline: none;
}

.services-contact-btn:hover {
  color: white;
  text-decoration: none;
  box-shadow: 3px 10px 20px rgba(0, 0, 0, 0.1);

  color: #fff;
  transform: translateY(-2px);
}

.services-contact-btn-arrow {
  margin-left: 10px;
  font-size: 1.44em !important;
}

.services-contact-btn-text {
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
}

.services-contact-us-text {
  font-family: "Montserrat", sans-serif !important;
  text-align: center;
  margin-bottom: 3vh;
  display: inline-block;
  vertical-align: middle;
}

.services-contact-request-icon {
  width: 100px;
  height: 100px;

  margin-bottom: 20px;

  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.services-contact-text-col {
  vertical-align: middle;
  display: table-row;
}

.services-contact-btn-col {
  vertical-align: middle;
  display: table-row;
}

@media only screen and (min-width: 600px) {
  .services-contact-us-text {
    margin-bottom: 0vh;
    width: 75% !important;
  }

  .services-contact-request-icon {
    margin-right: 20px;
    margin-bottom: 0px;
  }

  .services-contact-btn-arrow {
    margin-left: 15px;
  }
}
